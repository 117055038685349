import { render, staticRenderFns } from "./StockLog.vue?vue&type=template&id=644ac8bc&scoped=true&"
import script from "./StockLog.vue?vue&type=script&lang=js&"
export * from "./StockLog.vue?vue&type=script&lang=js&"
import style0 from "./StockLog.vue?vue&type=style&index=0&id=644ac8bc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "644ac8bc",
  null
  
)

export default component.exports