<template>
  <div class="layout">
    <Layout>
      <Layout>
        <Sider width="200">
          <GoodsManageMenu ref="goodsManageMenu" @func="getMsgFormMenu" :active="title_seclectd" :menuName="menuName"></GoodsManageMenu>
        </Sider>
        <Content>
          <NavTag ref="navTag" @func="getMsgFormMenu"></NavTag>
          <GoodsList v-if="title_seclectd == 1"></GoodsList>
          <GoodsCategory v-if="title_seclectd == 2"></GoodsCategory>
          <GoodsExplodeChange v-if="title_seclectd == 3"></GoodsExplodeChange>
<!--          <GoodsOut v-if="title_seclectd == 4"></GoodsOut>-->
<!--          <ShipOut v-if="title_seclectd == 5"></ShipOut>-->
<!--          <GoodsPick v-if="title_seclectd == 6"></GoodsPick>-->
<!--          <PickGoods v-if="title_seclectd == 7"></PickGoods>-->
          <Inventory v-if="title_seclectd == 8"></Inventory>
<!--          <StockTransfer v-if="title_seclectd == 9"></StockTransfer>-->
          <StockLog v-if="title_seclectd == 10"></StockLog>
<!--          <WarehouseInfo v-if="title_seclectd == 11"></WarehouseInfo>-->
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import GoodsManageMenu from '@/components/menu/GoodsManageMenu.vue';
import NavTag from '@/components/menu/NavTags.vue';
import GoodsList from '@/views/goodsManage/GoodsList.vue';
import Inventory from '@/views/goodsManage/Inventory.vue';
import StockLog from '@/views/goodsManage/StockLog.vue';
import GoodsCategory from '@/views/goodsManage/GoodsCategory.vue';
import GoodsExplodeChange from '@/views/goodsManage/GoodsExplodeChange.vue';


export default {
  name: "GoodsManage",
  components: {
    GoodsManageMenu,
    GoodsList,
    Inventory,

    StockLog,
    GoodsCategory,
    GoodsExplodeChange,
    NavTag
  },
  data() {
    return {
      title_seclectd: "1",
      menuName: "GoodsManage",
    };
  },
  mounted() {
    this.title_seclectd = localStorage.getItem(this.menuName) ? localStorage.getItem(this.menuName) : "1";

    if (this.$route.query.selected !== undefined ) {
      this.title_seclectd = this.$route.query.selected;
      localStorage.setItem(this.menuName, this.title_seclectd);
    }

    this.setNavInfo(this.title_seclectd)

  },

  methods: {
    getMsgFormMenu(data) {
      this.title_seclectd = data;
      this.setNavInfo(data)
    },
    setNavInfo(data) {
      this.$refs.navTag.setNavList(this.$route.path, this.title_seclectd)
      this.$refs.goodsManageMenu.setNavInfo(data)
    }
  },
};
</script>

<style scoped>
.ivu-layout {
  background-color: transparent;
}

.ivu-layout-sider {
  background-color: transparent;
}

.ivu-menu {
  height: 100%;
}

.laypout {
  display: flex;
}
</style>
