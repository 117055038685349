<template>
  <div id="goods_list">
    <div id="panel-header">
      <span class="top-title">商品列表</span>
      <div>
<!--        <Button type="info" class="client_export fs-12" @click="exportSubmit('goods_export_item')" icon="ios-download-outline">-->
<!--          导出-->
<!--        </Button>-->
<!--        <Button type="warning" icon="md-arrow-down" class="ml-10" @click="importProduct" v-if="aCheck.rightCheck('edit_product_list')">-->
<!--          导入-->
<!--        </Button>-->
        <Button type="primary" icon="md-add" class="ml-10"  @click="create('clientFormItem')" v-if="aCheck.rightCheck('edit_product_list')">
          添加
        </Button>
      </div>
    </div>

    <div id="filter-bar">
      <Card dis-hover>
        <Form :model="formItem" inline ref="formItem">
          <FormItem prop="part_no">
            <Input @on-blur="searchSubmit()" v-model="formItem.part_no" placeholder="请填写商品编号" class="w-200"/>
          </FormItem>

          <FormItem prop="part_name">
            <Input @on-blur="searchSubmit()" v-model="formItem.part_name" placeholder="请填写商品名称" class="w-200"/>
          </FormItem>

<!--          <FormItem prop="goods_type">-->
<!--            <Select @on-change="searchSubmit()" v-model="formItem.goods_type" placeholder="请选择商品类型" filterable class="w-200">-->
<!--              <Option v-for="item in common.goods_type" :value="item.id" :key="item.id">{{ item.name }}</Option>-->
<!--            </Select>-->
<!--          </FormItem>-->

          <FormItem prop="category_id">
            <Select @on-change="searchSubmit()" v-model="formItem.category_id" class="w-200" filterable  placeholder="请选择商品类别">
              <Option v-for="(option, index) in add.category_options" :value="option.value" :key="index">{{option.label}}</Option>
            </Select>
          </FormItem>

          <FormItem prop="empty_stock">
            <Select @on-change="searchSubmit()" v-model="formItem.empty_stock" class="w-200"   placeholder="是否去除0库存">
              <Option value="0" key="0">否</Option>
              <Option value="1" key="1">是</Option>
            </Select>
          </FormItem>

<!--          <FormItem prop="bom_label">-->
<!--            <Select @on-change="searchSubmit()" v-model="formItem.bom_label" class="w-200"  placeholder="是否关联bom部件">-->
<!--              <Option v-for="(option, index) in common.bom_select" :value="option.id" :key="index">{{option.name}}</Option>-->
<!--            </Select>-->
<!--          </FormItem>-->

<!--          <FormItem prop="goods_label">-->
<!--            <Select @on-change="searchSubmit()" v-model="formItem.goods_label" class="w-200" filterable clearable placeholder="请选择商品标签">-->
<!--              <Option v-for="(option, index) in common.label_all" :value="option.id" :key="index">{{option.name}}</Option>-->
<!--            </Select>-->
<!--          </FormItem>-->

          <FormItem style="margin-right: 0;">&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('formItem')">
              清除
            </Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div id="list" class="goods_list">
      <ColumnsSet ref="columns_set" :list_more="list_more"/>
      <Table :loading="common.list_loading" :columns="list_more.columns" :data="listInfo">
        <template slot-scope="{ row }" slot="certificate">
          <img v-if="row.certificate.length>0" :src="row.certificate[0].url+thumbnail_url" alt="" class="pointer preview-img" @click="imgShow(0, row.certificate)">
        </template>
        <template slot-scope="{ row }" slot="part_no">
          <strong @click="showDataInfo(row)" class="pointer">
            {{ row.part_no }}{{ row.part_no_postfix ? ('-' + row.part_no_postfix) : '' }}
          </strong>
        </template>
        <template slot-scope="{ row }" slot="part_name">
          <span>{{ row.part_name }}</span>
        </template>
        <template slot-scope="{ row }" slot="stock_all">
          <Button type="text" @click="row.stock_all?viewStockInfo(row.id):''">
            <strong>{{ row.stock_all }}</strong>
            <Icon v-show="row.stock_all" type="md-list"/>
<!--            <Icon v-show="row.stock_up!=row.stock_down && row.stock_all>=row.stock_up && row.stock_up!=0" class="danger-color fw-b" type="ios-arrow-round-up"/>-->
<!--            <Icon v-show="row.stock_up!=row.stock_down && row.stock_all<=row.stock_down && row.stock_down!=0" class="danger-color fw-b" type="ios-arrow-round-down"/>-->
          </Button>
        </template>
<!--        <template slot-scope="{ row }" slot="type">-->
<!--          <p>{{ getGoodsType(row.type) }}</p>-->
<!--        </template>-->

        <template slot-scope="{ row, index }" slot="goods_label">
          <Tag v-for="item in row.goods_label" :class="getLabelColor(item.color)" size="medium">
            <span style="color:#fff;">{{ item.name }}</span></Tag>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button type="text" size="small" @click="showDataInfo(row)" class="main-font-color">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>

          <Dropdown v-if="aCheck.rightCheck('edit_product_list')">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem><span @click="listEdit(row)" class="f12">商品修改</span></DropdownItem>
<!--              <DropdownItem><span @click="setLabel(row)" class="f12">标签设置</span></DropdownItem>-->
<!--              <DropdownItem><span @click="uploadFile(row.id, row.part_no)" class="f12">文件上传</span></DropdownItem>-->
              <DropdownItem><span @click="listDeleteAlert(row)" class="f12">商品删除</span></DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="listTotal" :page-size="pageSize" show-total show-elevator @on-change="changePage"
              :current="common.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>
    </div>

    <Modal v-model="add.modal" :title="add.model_title" width="1080">
      <div id="modalForm">
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="common.ruleInline" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="商品图片" prop="certificate">
                <div class="display-flex" style="align-items: center;">
                  <!-- fileList为回传属性    uploadParam用来传值                -->
                  <ImageUpload ref="image_upload" @fileList="getFileList" :uploadParam="clientFormItem.uploadParam"
                               :key="clientFormItem.uploadParam.key"/>
                  <Button class="fs-12 main-font-color" type="text" @click="getImgFromPictureLib">从素材库获取</Button>
                </div>
              </FormItem>
            </Col>

            <Col span="12">
              <FormItem label="商品号" prop="part_no">
                <Input v-model="clientFormItem.part_no" disabled placeholder="请填写商品号"
                       style="width: 120px;"></Input>&nbsp;<span>-</span>&nbsp;
                <Input v-model="clientFormItem.part_no_postfix" placeholder="请填写自定义编号"
                       style="width: 130px;" maxlength="20"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="商品名称" prop="part_name">
                <Input v-model="clientFormItem.part_name" placeholder="请填写商品名称"></Input>
              </FormItem>
            </Col>

          </Row>
<!--          <Row :gutter="20">-->
<!--            <Col span="12">-->
<!--              <FormItem label="库存上限" prop="stock_up">-->
<!--                <Input v-model="clientFormItem.stock_up" type="number" placeholder="请填写库存上限"></Input>-->
<!--              </FormItem>-->
<!--            </Col>-->
<!--            <Col span="12">-->
<!--              <FormItem label="库存下限" prop="stock_down">-->
<!--                <Input v-model="clientFormItem.stock_down" type="number" placeholder="请填写库存下限"></Input>-->
<!--              </FormItem>-->
<!--            </Col>-->
<!--          </Row>-->
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="商品类别" prop="category_id">
                <Select v-model="clientFormItem.category_id" filterable clearable placeholder="请选择商品类别">
                  <Option v-for="(option, index) in add.category_options" :value="option.value" :key="index">
                    {{ option.label }}
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <Input v-model="clientFormItem.remark" maxlength="600" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
<!--            <Col span="12">-->
<!--              <FormItem label="成本价" prop="cost_price">-->
<!--                <Input v-model="clientFormItem.cost_price" type="number" placeholder="请填写成本价"></Input>-->
<!--              </FormItem>-->
<!--            </Col>-->
          </Row>

<!--          <Row :gutter="20">-->
<!--            <Col span="12">-->
<!--              <FormItem label="批发价" prop="wholesale_price">-->
<!--                <Input v-model="clientFormItem.wholesale_price" placeholder="请填写批发价"-->
<!--                       type="number"></Input>-->
<!--              </FormItem>-->
<!--            </Col>-->
<!--            <Col span="12">-->
<!--              <FormItem label="销售价" prop="selling_price">-->
<!--                <Input v-model="clientFormItem.selling_price" placeholder="请填写销售价"-->
<!--                       type="number"></Input>-->
<!--              </FormItem>-->
<!--            </Col>-->
<!--          </Row>-->
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset('clientFormItem')">关闭</Button>
        <Button type="primary" :loading="add.modal_loading" @click="createGoods('clientFormItem')">保存</Button>
      </div>
    </Modal>

    <Modal v-model="import_stock.modal" :title="import_stock.model_title" width="960" :mask-closable="false">
      <div>
        <a href="https://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage/common/template/%E8%B4%A7%E5%93%81%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx">
          <Button type="text" icon="md-download" class="client_import_template main-font-color">
            下载导入模板
          </Button>
        </a>
      </div>
      <Row class="mt2 display-block" v-if="import_stock.stock_show_flag == 1">
        <Upload ref="stock_upload" type="drag" :before-upload="handleBeforeImport" action="-">
          <div style="padding: 120px 0">
            <Icon type="ios-cloud-upload" size="52" class="main-font-color"></Icon>
            <p>点击或者拖拽文件上传</p>
          </div>
        </Upload>
      </Row>
      <Spin fix v-show="import_stock.import_loading" size="large"></Spin>

      <Row style="text-align: center;" v-if="import_stock.stock_show_flag == 3">
        <div style="width:100%">
          <strong>{{ import_stock.import_tips }}</strong><br/>
          <span v-show="import_stock.response_stock__err_list" class="danger-color">{{
              import_stock.response_stock__err_list
            }}</span>
          <span v-show="import_stock.response_stock__err_list">商品，系统中已存在，无法再次导入，请留意</span>
        </div>
        <Progress :percent="import_stock.import_loading_progress" :stroke-color="['#108ee9', '#87d068']"/>
      </Row>

      <Row class="display-block" v-if="import_stock.stock_show_flag == 2">
        <Row class="padding-bottom-6 lh-20">
          <strong>总数：</strong><strong class="main-font-color">{{ import_stock.list.length }}</strong>
          <strong class="ml-20">重复：</strong><strong class="warning-color">{{
            import_stock.repeat_import_count
          }}</strong>
          <strong class="ml-20">异常：</strong><strong class="danger-color">{{ import_stock.err_import_count }}</strong>
          <span class="ml-20 fs12 disabled-color"><Icon type="ios-alert-outline"/> 重复和异常数据将无法录入到系统中，请知晓</span>
        </Row>
        <Table :columns="import_stock.stock_columns" :data="import_stock.list" :row-class-name="errRowClass" height="472">
          <template slot-scope="{ row, index }" slot="action">
            <Button :class="!row.format ? 'warning-color fs-12 ':'fs-12'" type="text" size="small" @click="stockUploadDelete(row, index)">
              <Icon type="ios-trash"/>
              删除
            </Button>
          </template>
        </Table>
      </Row>

      <div slot="footer">
        <Button v-show="import_stock.stock_show_flag == 2" @click="importClose">关闭</Button>
        <Button v-show="import_stock.stock_show_flag == 2" type="primary" :loading="import_stock.import_button_loading" @click="importStockAction">
          保存
        </Button>
      </div>
    </Modal>

    <Modal v-model="viewStock.modal" :title="viewStock.model_title" width="960" footer-hide>
      <Row class="mt2 display-block" v-show="viewStock.stockInfoList.length">
        <Table :columns="viewStock.in_already_table" :data="viewStock.stockInfoList">
          <template slot-scope="{ row }" slot="thumbnail_url">
            <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
          </template>
        </Table>
      </Row>
    </Modal>

    <!--查看-->
    <Modal v-model="viewGoods.modal" :title="viewGoods.modal_title" width="1020">
      <div class="modalForm view-body">
        <div class="view-img-body">
          <div class="view-img-main">
            <img :src="viewGoods.main_img" />
          </div>
          <div class="view-img-small" v-show="viewGoods.data.certificate.length>1">
            <img v-for="(item, index) in viewGoods.data.certificate" :class="viewGoods.imgSelected==index? 'img-selected':''" :src="item.url" @click="setViewImg(index,item.url)" />
          </div>
        </div>
        <div class="view-info">
          <div class="fw-bold">
              <span >{{ viewGoods.data.part_no }}{{viewGoods.data.part_no_postfix ? ('-' + viewGoods.data.part_no_postfix) : ''}}</span>
              <span class="fs-16 ml-10">{{ viewGoods.data.part_name }}</span>
          </div>
          <div>
            <Form :label-width="80" label-colon class="mt-10" label-position="left">
              <Row :gutter="20">
                <Col span="12">
                  <FormItem label="商品类别">
                    <span>{{ viewGoods.data.category_name }}</span>
                  </FormItem>
                </Col>
                <Col span="12">
                  <FormItem label="商品库存">
                    <span class="main-font-color">{{viewGoods.data.stock_all}}</span>
                  </FormItem>
                </Col>
              </Row>
              <Row :gutter="20">
                <Col span="12">
                  <FormItem label="批发价" prop="wholesale_price">
                    <span>{{ viewGoods.data.wholesale_price }}</span>
                  </FormItem>
                </Col>
                <Col span="12">
                  <FormItem label="销售价" prop="selling_price">
                    <span>{{ viewGoods.data.selling_price }}</span>
                  </FormItem>
                </Col>
              </Row>
              <Row :gutter="20">
                <Col span="12" v-show="special_rights_cost_price==1">
                  <FormItem label="成本价">
                    <span>{{ viewGoods.data.cost_price }}</span>
                  </FormItem>
                </Col>
                <Col span="12">
                  <FormItem label="备注">
                    <span>{{ viewGoods.data.remark }}</span>
                  </FormItem>
                </Col>
              </Row>
<!--              <Row :gutter="20">-->
<!--                <Col span="24">-->
<!--                  <FormItem label="条形码">-->
<!--                    <div style="display: flex;align-items: center;">-->
<!--                      <VueBarcode :value="viewGoods.data.bar_code?viewGoods.data.bar_code:(viewGoods.data.part_no + (viewGoods.data.part_no_postfix ? ('-' + viewGoods.data.part_no_postfix) : ''))" :options="{ width: 1, height: 70,lineColor:'#515a6e',fontSize:12 }" tag="svg"></VueBarcode>-->
<!--                      <Button type="text" class="main-font-color fs-12" icon="ios-download-outline" @click="downloadVueBarcode(viewGoods.data.bar_code?viewGoods.data.bar_code:(viewGoods.data.part_no + (viewGoods.data.part_no_postfix ? ('-' + viewGoods.data.part_no_postfix) : '')))">下载</Button>-->
<!--                    </div>-->
<!--                  </FormItem>-->
<!--                </Col>-->
<!--              </Row>-->
            </Form>
          </div>
        </div>
      </div>
<!--      <Row class="mt4 display-block">-->
<!--        <Card dis-hover >-->
<!--          <Button v-show="common.view_flag == 2" type="primary" slot="extra" size="small" @click="uploadFile(viewGoods.data.id, viewGoods.data.part_no)" class="f12" v-if="aCheck.rightCheck('edit_product_list')">-->
<!--            <Icon type="md-add"/>-->
<!--            添加文件-->
<!--          </Button>-->
<!--          <div>-->
<!--            <span style="margin:0 10px" :class="common.view_flag === 1 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(1)">商品库存</span>-->
<!--            <span style="margin:0 10px" :class="common.view_flag === 2 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(2)">关联文件</span>-->
<!--            <Divider style="margin: 14px 0px;"/>-->
<!--          </div>-->

<!--          <div v-show="common.view_flag == 1">-->
<!--            <Table :columns="viewGoods.stock_column" :data="viewGoods.stock_data">-->
<!--            </Table>-->
<!--          </div>-->
<!--          <div v-show="common.view_flag == 2">-->
<!--            <Table :columns="viewGoods.file_columns" :data="viewGoods.file_data" style="overflow: visible;">-->
<!--              <template slot-scope="{ row, index }" slot="file_name">-->
<!--                <span>{{ row.file_name }}</span>&nbsp;&nbsp;-->
<!--                <a :href="row.file_url">-->
<!--                  <Icon type="ios-download"/>-->
<!--                </a>-->
<!--              </template>-->
<!--              <template slot-scope="{ row, index }" slot="action">-->
<!--                <Button type="text" size="small" @click="deleteGoodsFile(row)"-->
<!--                        class="button-in-list-normal fs-12" v-if="aCheck.rightCheck('edit_product_list')">-->
<!--                  <Icon type="ios-trash"/>-->
<!--                  删除-->
<!--                </Button>-->
<!--              </template>-->
<!--            </Table>-->
<!--          </div>-->
<!--        </Card>-->
<!--      </Row>-->
      <div slot="footer">
        <Button @click="handleReset('clientFormItem')">关闭</Button>
      </div>
    </Modal>

    <!--图片预览-->
    <ImagePreview ref="image_preview"/>

    <!--文件上传-->
    <Modal v-model="upload_file.modal" :title="upload_file.modal_title">
      <div class="modalForm alignCenter">
        <Form ref="upload_file.item" :model="upload_file.item" :label-width="100" :rules="common.ruleInline"
              label-colon>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="文件上传" >
                <!--<Upload type="drag" action="/api/common/uploadGoodsFile" :data="upload_file.file_data"-->
                <Upload type="drag"
                        :action="upload_file.upload_host"
                        :data="upload_file.file_data"
                        :headers="upload_file.headers"
                        :max-size="204800"
                        :on-exceeded-size="handleMaxSize"
                        :on-success="uploadSuccess"
                        :before-upload="handleBeforeUpload"
                        ref="upload_file">
                  <div style="padding: 20px 0">
                    <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                    <p>点击或者拖拽文件上传</p>
                  </div>
                </Upload>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="文件类别" prop="file_category">
                <Select v-model="upload_file.item.file_category" placeholder="请选择商品文件类别">
                  <Option v-for="item in upload_file.file_category_type" :value="item.id"
                          :key="item.id">{{ item.name }}
                  </Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Form>

      </div>
      <div slot="footer">
        <Button @click="handleGoodsFileReset('upload_file.item')">取消</Button>
        <Button type="primary" :loading="upload_file.modal_loading" @click="bindGoodsFile">保存</Button>
      </div>
    </Modal>

    <!-- 标签设置   -->
    <Modal v-model="goods_label.modal" title="商品标签设置" width="800">
      <div class="modalForm">
        <Form :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="商品标签">
                <Select v-model="goods_label.data.selected" multiple style="width:260px">
                  <Option v-for="item in common.label_all" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="bindGoodsLabel">保存</Button>
      </div>
    </Modal>

    <!--素材库选择图片-->
    <PictureLib ref="picture_lib" @selected="getSelectedImg"/>

    <!-- 导出  -->
    <Modal v-model="goods_export.modal" title="商品导出" width="660">
      <div class="modalForm">
        <Form ref="goods_export_item" :model="goods_export.item" :label-width="100" label-colon>
          <Row :gutter="20" class="mt-20">
            <Col span="18" offset="3">
              <FormItem label="导出标题" prop="subject">
                <Input v-model="goods_export.item.subject" placeholder="请填写导出标题" type="text" maxlength="200"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetExportModal('goods_export_item')">关闭</Button>
        <Button type="primary" :loading="goods_export.modal_loading" @click="exportOperate">
          导出
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import VueBarcode from '@chenfengyuan/vue-barcode';

import {oss} from '@/plugins/oss';
import {upload} from '@/plugins/upload';

import ImageUpload from '@/components/common/ImageUpload.vue';
import ImagePreview from '@/components/common/Image/ImagePreview';
import PictureLib from '@/components/common/Image/PictureLib';
import ColumnsSet from "@/components/common/ColumnsSet";

import {remoteGoodsCategory, getGoodsCategoryList} from '@/api/goods/goodsCategory';
import {saveImportStockInBack, getGoodsList, apiExportGoodsList, apiGetGoodsStockInfo} from '@/api/goods/goods';

import {apiBindGoodsLabel} from "@/api/setting/goodsLabel";

import * as label_util from '@/utils/goodsLabel';


const clickOutside = {
  // 初始化指令
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }

    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler;
    document.addEventListener('click', documentHandler);
  },
  update() {
  },
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener('click', el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
}

import {covertSVG2Image} from '@/utils/svgToImg';

export default {
  name: "GoodsList",
  data() {
    return {
      special_rights_cost_price: 0,
      goods_label: {
        modal: false,
        all: [],
        selected: [],
        data: {
          selected: '',
          goods_id: 0,
        }
      },
      list_more: {
        column_key: 'goods_list_columns',
        visible: false,
        user_selected_column: [],
        all_columns: [
          {
            title: '商品编号',
            key: 'part_no',
            slot: 'part_no',
            align: 'center',
            fixed: true,
          },
          {
            title: '缩略图',
            key: 'certificate',
            slot: 'certificate',
            align: 'center',
          },
          {
            title: '商品名称',
            key: 'part_name',
            slot: 'part_name',
            minWidth:200,
            align: 'left',
          },
          {
            title: '商品类别',
            key: 'category_name',
            align: 'center',
          },
          {
            title: '成本价',
            key: 'cost_price',
            align: 'center',
          },
          {
            title: '批发价',
            key: 'wholesale_price',
            align: 'center',
          },
          {
            title: '销售价',
            key: 'selling_price',
            align: 'center',
          },
          {
            title: '总库存',
            key: 'stock_all',
            slot: 'stock_all',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 160,
            fixed: true,
          }
        ],
        base_selected_column: [
          '商品编号',
          '商品名称',
          '品类',
          '成本价',
          '销售价',
          '总库存',
          '操作'
        ],
        columns: []
      },
      thumbnail_url: oss.thumbnail_url,
      upload_file: {
        headers: {
          Authorization: '',
          AuthType: 1//1表示网站
        },
        modal: false,
        modal_loading: false,
        modal_title: '文件上传',
        item: {
          file_url: '',
          file_category: '',
          goods_id: '',
          file_size: '',
        },
        file_category_type: [],
        file_data: {
          goods_id: '',
          part_no: '',
        },
        upload_host: '',
      },
      viewGoods: {
        modal: false,
        modal_title: '查看详情',
        data: {
          certificate: [],
        },
        main_img: '',
        stock_column: [
          {
            title: '仓库',
            key: 'house_name',
            align: 'center'
          },
          {
            title: '库区',
            key: 'area_name',
            align: 'center'
          },
          {
            title: '库位',
            key: 'position_name',
            align: 'center'
          },
          {
            title: '库存',
            key: 'num',
            align: 'center'
          },
        ],
        stock_data: [],
        file_columns: [
          {
            title: '文件名',
            key: 'file_name',
            slot: 'file_name',
            align: 'center',
          },
          {
            title: '文件类型',
            key: 'file_category',
            align: 'center',
          },
          {
            title: '文件大小',
            key: 'file_size',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center',
          },
        ],
        file_data: []
      },
      // goods_type: 0,//1表示商品 2表示成品 3半成品 0代表全部
      formItem: {
        part_no: '',
        part_name: '',
        // goods_type: 0,
        category_id: 0,
        // bom_label: 0,
        empty_stock: '',
      },
      clientFormItem: {
        id: '',
        part_no: '',
        part_no_postfix: '',
        part_name: '',
        // unit: '',
        // stock_up: 0,
        // stock_down: 0,
        wholesale_price: 0,
        stock_all: 0,
        cost_price: 0,
        selling_price: 0,
        certificate: [],
        // specification: '',
        remark: '',
        // goods_type: '',
        category_id: '',
        uploadParam: {
          list_length: 3,
          upload_type: 2,//2表示凭证上传
          module_type: 1,//1表示商品
          prefix: '',
          list: [],
          key: 0,
        },
        category_name: '',
        // bar_code:'',
      },
      add: {
        modal: false,
        modal_loading: false,
        category_loading: false,
        category_options: [],
        model_title: '编辑商品信息',
      },
      import_stock: {
        import_loading: false,
        modal: false,
        model_title: '导入商品信息',
        stock_show_flag: 1,
        stock_columns: [
          {
            title: '商品名称',
            key: '商品名称',
            align: 'center',
          },
          {
            title: '商品类型',
            key: '商品类型',
            align: 'center',
          },
          {
            title: '商品号',
            key: '商品号',
            align: 'center',
          },
          {
            title: '规格',
            key: '规格',
            align: 'center',
          },
          {
            title: '库存上限',
            key: '库存上限',
            align: 'center',
          },
          {
            title: '库存下限',
            key: '库存下限',
            align: 'center',
          },
          {
            title: '成本价',
            key: '成本价',
            align: 'center',
          },
          {
            title: '销售价',
            key: '销售价',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
          }
        ],
        list: [],
        err_import_count: 0,
        repeat_import_count: 0,
        import_loading_progress: 0,
        import_loading_progress_doing: 0,
        import_loading_progress_total: 0,
        import_tips: '数据导入中...  导入数据时，请勿关闭弹窗、勿刷新网站',
        response_stock_err: [],
        response_stock__err_list: '',
        import_button_loading: false,
      },
      listInfo: [],
      viewStock: {
        model_title: '查看商品库存信息',
        modal: false,
        modal_loading: false,
        in_already_table: [
          {
            title: '商品编号',
            key: 'part_no',
            align: 'center'
          },
          {
            title: '商品名称',
            key: 'part_name',
            align: 'left',
            minWidth:260,
          },
          {
            title: '库存',
            key: 'num',
            align: 'center'
          },
          {
            title: '仓库',
            key: 'house_name',
            align: 'center'
          },
          // {
          //   title: '库区',
          //   key: 'area_name',
          //   align: 'center'
          // },
          // {
          //   title: '库位',
          //   key: 'position_name',
          //   align: 'center'
          // },
        ],
        stockInfoList: []
      },
      listTotal: 0,
      pageSize: 0,
      file: null,
      list: {
        page_size_key: 'goods_page_size_key',
        page_size_opts: [10, 15, 20, 30],
      },
      common: {
        view_flag: 1,
        // bom_select : [
        //   {
        //     id: 1,
        //     name: '关联商品BOM部件',
        //   },
        // ],
        ruleInline: {
          part_no: [
            {required: true, message: '请填写商品编号', trigger: 'blur'}
          ],
          part_name: [
            {required: true, message: '请填写商品名称', trigger: 'blur'},
            {Length: '200', message: '长度不能超过200', trigger: 'blur'}
          ],
          category_id: [
            {required: true, message: '请选择商品类别'}
          ],
        },
        // goods_type: [
        //   {
        //     id: 1,
        //     name: '物料',
        //   },
        //   {
        //     id: 2,
        //     name: '成品',
        //   },
        //   {
        //     id: 3,
        //     name: '半成品',
        //   },
        //   {
        //     id: 4,
        //     name: '虚拟',
        //   },
        // ],
        page: 1,
        list_loading: false,
        category_list: [],
        label_all: [],
        // unit_all: [],
      },
      goods_export: {
        modal_loading: false,
        modal: false,
        item: {
          subject: '',
        },
      }
    };
  },
  methods: {
    setViewImg(key, img) {//设置查看大图
      this.viewGoods.main_img = img;
      this.viewGoods.imgSelected = key;
    },
    exportOperate() {
      this.goods_export.modal_loading = true;
      let param = {
        part_no: this.formItem.part_no,
        part_name: this.formItem.part_name,
        // type: this.formItem.goods_type,//1表示商品
        category_id: this.formItem.category_id,
        goods_label: this.formItem.goods_label,
        // bom_label: this.formItem.bom_label,
        subject: this.goods_export.item.subject,
      };

      apiExportGoodsList( param).then((res) => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
        } else {
          this.$Message.error(res.data.err_msg);
          this.goods_export.modal_loading = false;
        }
        this.goods_export.modal = false;
      }).catch((error) => {
        this.$Message.error('导出失败');
        this.goods_export.modal_loading = false;
      });
    },
    handleResetExportModal(name) {
      this.$refs[name].resetFields();
      this.goods_export.modal = false;
      this.goods_export.modal_loading = false;
    },
    exportSubmit(name) {
      this.$refs[name].resetFields();
      this.goods_export.modal_loading = false;
      this.goods_export.modal = true;
    },
    getSelectedImg(item) {
      if (item.length > 0) {
        for (let i=0; i<item.length; i++) {
          if (this.clientFormItem.certificate.length>=3) {
            this.clientFormItem.certificate.shift();
          }
          this.clientFormItem.certificate.push({url: item[i]});
        }

        this.clientFormItem.uploadParam.list = this.clientFormItem.certificate;
        this.clientFormItem.uploadParam.key += 1;
      }
    },
    getImgFromPictureLib() {
      this.$refs.picture_lib.openSelect();
    },
    downloadVueBarcode(name) {
      covertSVG2Image(document.querySelector('svg'), name)
    },
    setLabel(row) {
      this.goods_label.data.goods_id = row.id;
      this.goods_label.data.selected = row.goods_label_all;
      this.goods_label.modal = true;
    },
    bindGoodsLabel() {
      apiBindGoodsLabel(this.goods_label.data).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.goods_label.modal = false;
          this.getGoodsList();
        }
      })
    },
    getLabelColor(color) {
      switch (color) {
        case 'one':
          return 'label-color-one';
        case 'two':
          return 'label-color-two';
        case 'three':
          return 'label-color-three';
        case 'four':
          return 'label-color-four';
        case 'five':
          return 'label-color-five';
        case 'six':
          return 'label-color-six';
        case 'seven':
          return 'label-color-seven';
        case 'eight':
          return 'label-color-eight';
        case 'nine':
          return 'label-color-nine';
        case 'ten':
          return 'label-color-ten';
      }
    },
    getGoodsLabel() {
      label_util.getGoodsLabel().then(res => {
        this.common.label_all = res;
      });
    },
    // getUnitAll() {
    //   unit_util.getUnitAll().then(res => {
    //     this.common.unit_all = res;
    //   });
    // },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.pageSize = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
    pageSizeChange(page_size) {
      this.pageSize = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getGoodsList();
    },
    importStockAction() {
      this.import_stock.import_button_loading = true;
      if (this.import_stock.list.length <= 0) {
        this.$Message.error('导入数据为空，请留意');
        return;
      }

      //判断异常和重复数据是否要忽略
      if (this.import_stock.err_import_count > 0 || this.import_stock.repeat_import_count > 0) {
        this.$Modal.confirm({
          title: "导入数据校验",
          content: '即将导入系统的数据中存在重复数据和异常数据，请否清理后再进行保存操作？异常数据和重复数据将不会存入系统！',
          okText: '确定',
          cancelText: '忽略',
          onOk: () => {
            this.import_stock.stock_show_flag = 2;
            this.import_stock.import_button_loading = false;
          },
          onCancel: () => {
            this.saveImportStock();
          }
        });
      } else {
        this.saveImportStock();
      }
    },
    saveImportStock() {
      this.import_stock.stock_show_flag = 3;
      this.import_stock.import_loading_progress = 0;
      this.import_stock.import_loading_progress_doing = 0;

      let list_length = this.import_stock.list.length;

      let push_data = [];
      let steps = 0;

      let split = 200;
      this.import_stock.import_loading_progress_total = Math.ceil((list_length - this.import_stock.err_import_count - this.import_stock.repeat_import_count) / split);

      for (let i = 0; i < list_length; i++) {
        steps++;

        //过滤重复和异常数据
        if (this.import_stock.list[i].format == 1) {
          let tmp = {
            product_name: this.import_stock.list[i].商品名称,
            product_type: this.import_stock.list[i].商品类型,
            product_number: this.import_stock.list[i].商品号,
            // stock_up: this.import_stock.list[i].库存上限,
            // stock_down: this.import_stock.list[i].库存下限,
            cost_price: this.import_stock.list[i].成本价,
            selling_price: this.import_stock.list[i].销售价,
          };

          push_data.push(tmp);

          if (push_data.length >= split) {
            //数据保存操作
            this.saveImportStockInBack(push_data);
            push_data = [];
          }
        }
      }

      //剩余数据保存
      if (push_data.length > 0) {
        this.saveImportStockInBack(push_data);
        push_data = [];
      }

    },
    saveImportStockInBack(data) {
      saveImportStockInBack({import_data: data}).then(response => {
        this.import_stock.import_loading_progress_doing++;
        this.import_stock.import_loading_progress = Math.round(this.import_stock.import_loading_progress_doing / this.import_stock.import_loading_progress_total) * 100;

        if (response.data.results.length > 0) {
          this.import_stock.response_stock_err = this.import_stock.response_stock_err.concat(response.data.results)
        }

        if (this.import_stock.import_loading_progress == 100) {
          this.import_stock.import_tips = '导入完成';
          this.import_stock.import_button_loading = false;
          if (response.data.results.length > 0) {
            this.import_stock.response_stock__err_list = [...new Set(this.import_stock.response_stock_err)].join('、');
          }
        }
      });
    },
    errRowClass(row, index) {
      //标红有问题的行数
      if (row.format == 2) {
        return 'warning-color';
      }

      if (row.format == 3) {
        return 'danger-color';
      }

      return '';
    },
    stockUploadDelete(row, index) {
      if (row.format == 3) {
        this.import_stock.err_import_count--;
      }

      if (row.format == 2) {
        this.import_stock.repeat_import_count--;
      }

      this.import_stock.list.splice(index, 1);
    },
    handleBeforeImport(file) {
      this.import_stock.import_loading = true;
      const fileType = this.getFileType(file.name).toString();

      if (!['.xlsx'].includes(fileType)) {
        this.$Message.error('导入数据格式目前仅支持 .XLSX 文件');
        this.import_stock.import_loading = false;
        return false;
      }

      const read = new FileReader();
      read.readAsBinaryString(file);
      read.onload = (event) => {
        const data = event.currentTarget.result;

        let excelData = XLSX.read(data, {type: 'binary'});
        //excelData.SheetNames[0]是获取Sheets中第一个Sheet的名字
        //excelData.Sheets[Sheet名]获取第一个Sheet的数据
        const json = XLSX.utils.sheet_to_json(excelData.Sheets[excelData.SheetNames[0]]);
        if (json) {
          let repeat_data = [];
          //数据校验
          for (let i = 0; i < json.length; i++) {
            json[i].format = 3;//异常
            if (json[i].商品名称 != undefined && json[i].商品名称 && json[i].商品类型 != undefined && json[i].商品类型) {
              json[i].format = 1;//正常

              //判断商品类型是否正确
              if (!['成品', '半成品', '物料', '虚拟'].includes(json[i].商品类型)) {
                json[i].format = 3;
              } else {
                //判断是否有重复数据
                let repeatInfo = json[i].商品名称 + json[i].商品号;
                if (repeat_data.includes(repeatInfo)) {
                  json[i].format = 2;//重复
                  this.import_stock.repeat_import_count++;
                } else {
                  repeat_data.push(repeatInfo);
                }
              }
            }
            json[i].库存上限 = json[i].库存上限 ? (isNaN(parseFloat(json[i].库存上限)) ? '' : parseFloat(json[i].库存上限)) : '';
            json[i].库存下限 = json[i].库存下限 ? (isNaN(parseFloat(json[i].库存下限)) ? '' : parseFloat(json[i].库存下限)) : '';
            json[i].成本价 = json[i].成本价 ? (isNaN(parseFloat(json[i].成本价)) ? '' : parseFloat(json[i].成本价)) : '';
            json[i].销售价 = json[i].销售价 ? (isNaN(parseFloat(json[i].销售价)) ? '' : parseFloat(json[i].销售价)) : '';

            if (json[i].format == 3) {
              this.import_stock.err_import_count++;
            }
          }

          this.import_stock.list = json;
          this.import_stock.stock_show_flag = 2;
        } else {
          this.$Message.error('文件上传有误，请核对文件格式');
        }

        this.import_stock.import_loading = false;
      };

      return false;
    },
    getFileType(filepath) {
      const reg = /.[a-zA-Z0-9]+$/;
      return reg.exec(filepath);
    },
    remoteGoodsCategory(query) {
      if (query !== '') {
        this.add.category_loading = true;
        remoteGoodsCategory({query: query}).then(response => {
          this.add.category_loading = false;
          if (response.data.results.length > 0) {
            const list = response.data.results.map(item => {
              return {
                value: item.id,
                label: item.name
              };
            });

            this.add.category_options = list.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.add.category_options = this.common.category_list;
          }
        });
      } else {
        this.add.category_options = this.common.category_list;
      }
    },
    goodsCategoryList() {
      getGoodsCategoryList({size: 500}).then(response => {
        this.common.category_list = this.add.category_options = response.data.results.list.map(item => {
          return {
            value: item.id,
            label: item.name
          };
        });
      });
    },
    getFileList(val) {
      this.clientFormItem.certificate = [];
      for (let i = 0; i < val.length; i++) {
        this.clientFormItem.certificate[i] = {};
        this.clientFormItem.certificate[i].url = val[i].url;
      }
    },
    deleteGoodsFile(row) {
      this.$Modal.confirm({
        title: "删除商品文件信息",
        content: '确定删除文件商品信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteGoodsFileInfo(row);
        },
        onCancel: () => {
        }
      });
    },
    deleteGoodsFileInfo(row) {
      let param = {
        id: row.id,
        goods_id: row.goods_id
      };
      this.$axios.post('/api/goods/deleteGoodsFile', param).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getBindGoodsFile(row.goods_id);
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },
    handleBeforeUpload(file) {
      let left_file_capacity = localStorage.getItem('left_file_capacity');

      const check = this.$refs.upload_file.fileList.length < 1;
      const capacity_check = parseFloat(left_file_capacity) > 0;

      if (!check) {
        this.$Notice.warning({
          title: '不支持多文件上传'
        });
      }

      if (!capacity_check) {
        this.$Notice.warning({
          title: '账号存储容量已满，请联系客服！'
        });
      }

      if (check && capacity_check) {
        let param = {
          goods_id: this.upload_file.file_data.goods_id,
          type: 1,//1表示商品文件上传
        };
        this.setOssSignature(param);

        return new Promise((resolve, reject) => {
          let time_now = new Date().getTime();
          //加前缀防止相同名称文件替换了
          let fileName = this.upload_file.file_data.part_no + time_now.toString() + file.name;
          let info = oss.ossInfo(fileName);
          this.upload_file.upload_host = info.host;
          this.upload_file.file_data = info;
          resolve(file);
        });
      }
      return check && capacity_check;
    },
    setOssSignature(param) {
      if (oss.expire < parseInt(new Date().getTime().toString().substr(0, 10))) {
        oss.getSignature(param);
      }
    },
    selectTap(flag) {
      this.common.view_flag = flag;
    },
    uploadFile(goods_id, part_no) {
      //获取分类
      this.$axios.get('/api/goods/getGoodsFileCategory').then((response) => {
        if (response.data.err_no == 0) {
          this.upload_file.file_category_type = response.data.results;
        }
      });

      //文件上传
      this.$refs.upload_file.fileList = [];
      this.upload_file.item.file_url = '';
      this.upload_file.item.file_category = '';
      this.upload_file.item.goods_id = goods_id;
      this.upload_file.file_data.goods_id = goods_id;
      this.upload_file.file_data.part_no = part_no;
      this.upload_file.modal = true;
      this.upload_file.modal_loading = false;
    },
    handleGoodsFileReset(name) {
      this.$refs[name].resetFields();
      this.upload_file.modal = false;
    },
    uploadSuccess(res, file, fileList) {
      if (res.err_no == -1) {
        this.$Message.error(res.err_msg);
        this.$refs.upload_file.fileList = [];
      } else {
        this.upload_file.item.file_url = res.results.url;
        this.upload_file.item.file_name = file.name;
        this.upload_file.item.file_size = file.size;
      }
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: '上传文件过大',
        desc: '上传  ' + file.name + ' 太大, 请不要超过 200M.'
      });
    },
    bindGoodsFile() {
      this.upload_file.modal_loading = true;

      if (!this.upload_file.item.file_url) {
        this.$Message.error('请先上传文件');
        this.upload_file.modal_loading = false;
        return;
      }

      if (!this.upload_file.item.file_category) {
        this.$Message.error('请选择文件分类');
        this.upload_file.modal_loading = false;
        return;
      }

      let param = {
        file_category: this.upload_file.item.file_category,
        file_url: this.upload_file.item.file_url,
        file_name: this.upload_file.item.file_name,
        goods_id: this.upload_file.item.goods_id,
        file_size: this.upload_file.item.file_size
      };

      this.$axios.post('/api/goods/bindGoodsFile', param).then((response) => {
        if (response.data.err_no === 0) {
          this.$Message.success(response.data.results);
          this.upload_file.modal = false;
          this.getBindGoodsFile(this.upload_file.item.goods_id);
        } else {
          this.$Message.error(response.data.err_msg);
          this.upload_file.modal_loading = false;
        }
      }).catch((error) => {
        this.$Message.error('保存失败');
        this.upload_file.modal_loading = true;
      });
    },
    //查看图片
    imgShow(value, img_info) {
      this.$refs.image_preview.imgPreview(value, img_info);
    },
    //打开查看页面
    showDataInfo(row) {
      this.viewGoods.data = row;
      if (this.viewGoods.data.certificate.length>0) {
        this.viewGoods.main_img = this.viewGoods.data.certificate[0].url;
      } else {
        this.viewGoods.main_img = 'https://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage/common/placeholder.jpg';
      }

      this.viewGoods.data.cost_price = parseFloat(this.viewGoods.data.cost_price);
      this.viewGoods.data.selling_price = parseFloat(this.viewGoods.data.selling_price);

      apiGetGoodsStockInfo({goods_id: row.id}).then((res) => {
        if (res.data.err_no === 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].num = parseFloat(res.data.results.list[i].num);
          }
          this.viewGoods.stock_data = res.data.results.list;
        } else {
          this.$Message.error(res.data.err_msg);
        }
      });

      this.viewGoods.modal = true;
      this.getBindGoodsFile(row.id);
    },
    getBindGoodsFile(goods_id) {
      //获取商品文件信息
      this.$axios.get('/api/goods/getBindGoodsFile?goods_id=' + goods_id).then((response) => {
        if (response.data.err_no == 0) {
          this.viewGoods.file_data = response.data.results;
        }
      });
    },
    handleReset(name) {
      //弹出框重置
      this.add.modal = false;
      this.add.modal_loading = false;
      this.$refs[name].resetFields();
      this.viewGoods.modal = false;
    },
    importProduct() {
      this.import_stock.stock_show_flag = 1;
      this.import_stock.err_import_count = 0;
      this.import_stock.repeat_import_count = 0;
      this.import_stock.list = [];
      //导入产品数据
      this.import_stock.modal = true;
    },
    importClose() {
      this.import_stock.modal = false;
    },
    create(name) {
      //创建商品信息
      this.add.modal_loading = false;
      this.clientFormItem.id = '';
      this.clientFormItem.certificate = [];
      this.clientFormItem.part_no_postfix = '';
      this.add.modal = true;
      this.$refs[name].resetFields();

      this.clientFormItem.uploadParam.list = [];
      this.clientFormItem.uploadParam.key += 1;

      this.$axios.get('/api/goods/getGoodsId').then((response) => {
        if (response.data.err_no == 0) {
          this.clientFormItem.part_no = response.data.results;
          this.clientFormItem.uploadParam.prefix = this.clientFormItem.part_no;
        }
      });
    },
    listEdit(row) {
      this.clientFormItem.id = row.id;
      this.clientFormItem.part_no = row.part_no;
      this.clientFormItem.part_no_postfix = row.part_no_postfix;
      this.clientFormItem.part_name = row.part_name;
      // this.clientFormItem.stock_up = row.stock_up;
      // this.clientFormItem.stock_down = row.stock_down;
      this.clientFormItem.stock_all = row.stock_all;
      this.clientFormItem.cost_price = row.cost_price;
      this.clientFormItem.selling_price = row.selling_price;
      this.clientFormItem.certificate = row.certificate;
      this.clientFormItem.category_id = row.category_id;
      this.clientFormItem.remark = row.remark;
      // this.clientFormItem.goods_type = row.type;
      // this.clientFormItem.unit = row.unit;
      // this.clientFormItem.specification = row.specification;
      // this.clientFormItem.bar_code = row.bar_code;
      this.clientFormItem.uploadParam.prefix = this.clientFormItem.part_no;
      this.clientFormItem.uploadParam.list = JSON.parse(JSON.stringify(this.clientFormItem.certificate));
      this.clientFormItem.uploadParam.key += 1;
      this.clientFormItem.category_name = row.category_name;

      this.add.modal = true;
      this.add.modal_loading = false;
    },
    viewStockInfo(goods_id) {
      apiGetGoodsStockInfo({goods_id: goods_id}).then((res) => {
        if (res.data.err_no === 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].num = parseFloat(res.data.results.list[i].num);
          }
          this.viewStock.stockInfoList = res.data.results.list;
        } else {
          this.$Message.error(res.data.err_msg);
        }
      });
      this.viewStock.modal = true;
    },
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除商品信息",
        content: '确定删除商品信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },
    deleteListInfo($id) {
      this.$axios.post('/api/goods/deleteListInfo', {id: $id}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getGoodsList();
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },
    createGoods(name) {
      this.add.modal_loading = true;

      // if (parseFloat(this.clientFormItem.stock_down)>0 && parseFloat(this.clientFormItem.stock_up)>0 && parseFloat(this.clientFormItem.stock_down) >= parseFloat(this.clientFormItem.stock_up)) {
      //   this.$Message.error('库存下限的值不能高于或者等于库存上限值，请留意！');
      //   this.add.modal_loading = false;
      //   return;
      // }

      let certificate = [];
      for (let i=0; i<this.clientFormItem.certificate.length; i++) {
        let tmp = {};
        tmp.url = this.clientFormItem.certificate[i].url;
        certificate.push(tmp);
      }

      this.$refs[name].validate((valid) => {
        if (valid) {
          let param = {
            certificate: certificate,
            id: this.clientFormItem.id,
            part_no: this.clientFormItem.part_no.concat('-', this.clientFormItem.part_no_postfix),
            part_name: this.clientFormItem.part_name,
            // stock_down: this.clientFormItem.stock_down,
            // stock_up: this.clientFormItem.stock_up,
            // cost_price: this.clientFormItem.cost_price,
            // selling_price: this.clientFormItem.selling_price,
            // wholesale_price: this.clientFormItem.wholesale_price,
            remark: this.clientFormItem.remark,
            // type: this.clientFormItem.goods_type,
            // unit: this.clientFormItem.unit,
            // specification: this.clientFormItem.specification,
            // bar_code: this.clientFormItem.bar_code,
            category_id: this.clientFormItem.category_id,
          }

          this.$axios.post('/api/goods/createGoods', param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success('商品信息保存成功');
              this.$refs[name].resetFields();
              this.add.modal = false;
              this.getGoodsList();
            } else {
              this.$Message.error(response.data.err_msg);
              this.add.modal_loading = false;
            }
          }).catch((error) => {
            this.$Message.error('保存失败');
            this.add.modal_loading = false;
          });
        } else {
          this.add.modal_loading = false;
        }
      });

    },
    searchSubmit() {
      this.common.page = 1;
      this.getGoodsList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.common.page = 1;
      this.getGoodsList();
    },
    getGoodsList() {
      this.common.list_loading = true;
      let param = {
        page: this.common.page,
        part_no: this.formItem.part_no,
        part_name: this.formItem.part_name,
        // type: this.formItem.goods_type,//1表示商品
        category_id: this.formItem.category_id,
        size: this.pageSize,
        goods_label: this.formItem.goods_label,
        empty_stock: this.formItem.empty_stock,
        // bom_label: this.formItem.bom_label,
      };
      getGoodsList(param).then((res) => {
        this.common.list_loading = false;
        if (res.data.err_no == 0) {
          for(let i = 0; i < res.data.results.list.length; i++) {
            res.data.results.list[i].stock_all = parseFloat(res.data.results.list[i].stock_all);
            res.data.results.list[i].selling_price = parseFloat(res.data.results.list[i].selling_price);
            res.data.results.list[i].cost_price = parseFloat(res.data.results.list[i].cost_price);
            res.data.results.list[i].wholesale_price = parseFloat(res.data.results.list[i].wholesale_price);
          }
          this.listInfo = res.data.results.list;
          this.listTotal = res.data.results.total;
          this.pageSize = res.data.results.size;
        }
      });
    },
    changePage(page) {
      this.common.page = page;
      this.getGoodsList();
    },
    // changeGoodsNumberPrefix(item) {
    //   const part_no = item.part_no.replace(/[^0-9]+/ig, '');
    //   switch (item.goods_type) {
    //     case 1:
    //       item.part_no = 'WL' + part_no;
    //       break;
    //     case 2:
    //       item.part_no = 'CP' + part_no;
    //       break;
    //     case 3:
    //       item.part_no = 'BCP' + part_no;
    //       break;
    //     case 4:
    //       item.part_no = 'XN' + part_no;
    //       break;
    //   }
    // }
  },
  mounted() {

    this.$refs.columns_set.getUserColumns();

    this.setSelectedPageSize();

    this.getGoodsList();

    this.upload_file.headers.Authorization = localStorage.getItem('api_token');

    this.goodsCategoryList();

    this.getGoodsLabel();
    // this.getUnitAll();

    upload.getUploadType();
  },
  created() {
    this.special_rights_cost_price = localStorage.getItem('special_rights_cost_price');
    if (this.special_rights_cost_price != 1) {
      for (let i = 0; i<this.list_more.all_columns.length; i++) {
        if ( this.list_more.all_columns[i]?.key == 'cost_price') {
          this.list_more.all_columns.splice(i, 1);
          break;
        }
      }

      for (let i = 0; i<this.list_more.base_selected_column.length; i++) {
        if (this.list_more.base_selected_column[i] == 'cost_price') {
          this.list_more.base_selected_column.splice(i, 1);
          break;
        }
      }
    }
    this.aCheck.plateName = 'product_manage';
  },
  directives: {clickOutside},
  components: {
    ImageUpload,
    ImagePreview,
    PictureLib,
    VueBarcode,
    ColumnsSet
  }
};
</script>

<style scoped>
.select-flag {
  font-size: 16px;
  font-weight: 500;
}

#goods_list {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  display:flex;
  justify-content: space-between;
  align-items: center;
  border: none;
}

#goods_list .ivu-btn-small {
  font-size: 12px !important;
}

#modalForm {
  width: 100%;
}
.view-body {
  display:flex;
}

.view-body .img-selected {
  border: 2px solid #2775ff;
}

.view-img-body {
  width: 400px;
}

.view-img-main {
  width: 400px;
  height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-img-main img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.view-img-small {
  margin:6px 10px 6px 10px;
  text-align: center;
}
.view-img-small img {
  width:50px;
  height:50px;
  margin:6px;
}

.view-info {
  width:100%;
  margin-left:20px;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_import_template {
  position: absolute;
  left: 140px;
  top: 10px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
  position: relative;
}

#list .page {
  margin-top: 16px;
}
</style>
<style>
#goods_list .ivu-card {
  background-color: #fafafa;
}

#goods_list #filter-bar .ivu-card-body {
  padding: 6px 16px 16px 16px;
}

#goods_list .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#goods_list .ivu-form-item {
  margin-bottom: 0;
  margin-top: 10px;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

#list .ivu-spin-fix {
  top: 40px !important;
  height: calc(100% - 40px) !important;
}

.view-body .ivu-form-item-label {
  color:#777777 !important;
}

.goods_list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.goods_list .ivu-table-wrapper {
  overflow: visible;
}

.goods_list .ivu-tag {
  border:0;
}

.view-body .ivu-divider-horizontal {
  margin: 14px 0px;
}
</style>
