<template>
  <div id="inventory">
    <div id="panel-header">
      <span class="top-title">商品盘点</span>
      <Button type="primary" class="client_create" @click="createOrderInfo('createOrder.item')" v-if="aCheck.rightCheck('edit_goods_inventory')">
        创建盘点计划
      </Button>
    </div>
    <!--列表筛选条件-->
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="list.search_item" inline>
          <FormItem prop="main_no">
            <i-input @on-blur="searchSubmit()" v-model="list.search_item.main_no" placeholder="请填写计划编号" class="w-200"/>
          </FormItem>

          <FormItem prop="status">
            <Select @on-change="searchSubmit()" v-model="list.search_item.status" placeholder="请选择计划状态" filterable class="w-200">
              <Option v-for="item in list.all_status" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="inventory">
      <Table :columns="list.columns_info" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong @click="viewOrder(row)" class="pointer">{{ row.main_no }}</strong>
        </template>
        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>

        <template slot-scope="{ row }" slot="created_at">
          <span>{{ row.created_at.substring(0, 10) }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">

          <Button v-show="row.status==1" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="startInventory(row)" v-if="aCheck.rightCheck('operate_goods_inventory')">
            <Icon type="ios-checkmark-circle"/>
            开始盘点
          </Button>
          <Button v-show="row.status==2" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="viewOrder(row)" v-if="aCheck.rightCheck('operate_goods_inventory')">
            <Icon type="ios-checkmark-circle"/>
            继续盘点
          </Button>
          <Button v-show="row.status==3" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="viewOrder(row)" v-if="aCheck.rightCheck('operate_goods_inventory')">
            <Icon type="ios-checkmark-circle"/>
            执行计划
          </Button>
          <Button v-show="row.status==4" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="viewOrder(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>

          <Button v-show="row.status==0" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="viewOrder(row)" v-if="aCheck.rightCheck('valid_goods_inventory')">
            <Icon type="ios-checkmark-circle"/>
            审核
          </Button>
          <Dropdown v-show="row.status==0" v-if="aCheck.rightCheck('edit_goods_inventory')">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-show="row.status==0">
                <span @click="listEdit(row)" class="f12">修改计划</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==0">
                <span @click="listDeleteAlert(row)" class="f12">删除计划</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>

    </div>

    <!--添加修改采购计划-->
    <Modal v-model="createOrder.modal" :title="createOrder.model_title" width="960">
      <div class="modalForm">
        <Form ref="createOrder.item" :model="createOrder.item" :label-width="100"
              :rules="createOrder.ruleInline"
              label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="计划单号" prop="main_no">
                <Input v-model="createOrder.item.main_no" disabled placeholder="请填写计划单号"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="计划名称" prop="name">
                <Input v-model="createOrder.item.name" placeholder="请填写计划名称"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="盘点仓库" prop="house_id">
                <Select v-model="createOrder.item.house_id" placeholder="请选择仓库">
                  <Option v-for="o in common.house_all" :value="o.house_id" :key="o.house_id">
                    {{ o.house_name }}({{ o.house_no }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <Input v-model="createOrder.item.remark" maxlength="200" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="addHandleReset(createOrder.item)">取消</Button>
        <Button type="primary" :loading="createOrder.modal_loading"
                @click="createInventoryOrder('createOrder.item')">保存
        </Button>
      </div>
    </Modal>

    <!--审核-->
    <Modal v-model="view.modal" :title="view.model_title" width="1080">
      <div class="modalForm">
        <Form :model="view.item" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="盘点单号" prop="main_no">
                <span>{{ view.item.main_no }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="计划名称" prop="name">
                <span>{{ view.item.name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="盘点仓库" prop="house_name">
                <span>{{ view.item.house_name }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="创建时间" prop="created_at">
                <span>{{ view.item.created_at }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="单据状态" prop="status">
                <span :style="getStatusColor(view.item.status)">{{ getStatusText(view.item.status) }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="操作员" prop="operator">
                <span>{{ view.item.operator }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <span>{{ view.item.remark }}</span>
              </FormItem>
            </Col>
          </Row>

          <!--盘点明细-->
          <Row class="mt2 display-block" v-if="view.item.status!=0">
            <Card :bordered="false">
              <p slot="title">盘点明细</p>
              <Button type="primary" slot="extra" size="small" @click="addGoods(0)" class="f12"
                      v-if="view.item.status==1 || view.item.status==2">
                <Icon type="md-add"/>
                新增明细
              </Button>
              <Table :columns="view.goods.columns" :data="view.goods.data" style="overflow: visible;">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                </template>

                <template slot-scope="{ row, index }" slot="real_total">
                  <p v-show="row.add_flag">
                    <i-input type="number" v-model="row.real_total" @on-change="totalDiff(row)"/>
                  </p>
                  <span v-show="!row.add_flag">{{ row.real_total }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <Button type="text" :loading="row.modal_loading" size="small" v-show="row.add_flag"
                          class="button-in-text-blue fs-12"
                          @click="saveInventoryDetail(index, row,view.item.id)"
                          v-if="[1,2].includes(view.item.status)">
                    <!--<Icon type="ios-checkmark-circle"/>-->
                    <Icon type="ios-archive"/>
                    保存
                  </Button>
                  <Button type="text" size="small" v-show="row.add_flag"
                          class="button-in-list-normal fs-12"
                          @click="simpleDeleteInventoryDetail(index, row)"
                          v-if="[1,2].includes(view.item.status)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>

                  <Button type="text" size="small" v-show="!row.add_flag"
                          class="button-in-list-normal fs-12"
                          @click="editInventoryDetail(row)"
                          v-if="[1,2].includes(view.item.status)">
                    <Icon type="ios-create"/>
                    修改
                  </Button>
                  <Button type="text" size="small" v-show="!row.add_flag"
                          class="button-in-list-normal fs-12" @click="deleteInventoryDetail(index, row)"
                          v-if="[1,2].includes(view.item.status)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                  <span v-if="[3,4].includes(view.item.status)" class="disabled-color">------</span>
                </template>
              </Table>
            </Card>
          </Row>

          <!--待盘点库区明细-->
<!--          <Row class="mt2 display-block" v-if="[1,2].includes(view.item.status)">-->
<!--            <Card :bordered="false">-->
<!--              <p slot="title">-->
<!--                待盘点仓库库区明细-->
<!--                <Tooltip max-width="300" content="该模块用于显示仓库库区里待盘点的商品，帮助用户知晓剩余待盘点的商品。右侧有开关，用于显示和关闭该模块"-->
<!--                         theme="light">-->
<!--                  <Icon type="ios-help-circle" class="cgrey"/>-->
<!--                </Tooltip>-->
<!--              </p>-->
<!--              <p slot="extra">-->
<!--                <i-switch size="large" v-model="view.house.flag" true-color="#2775ff">-->
<!--                  <span slot="open">隐藏</span>-->
<!--                  <span slot="close">显示</span>-->
<!--                </i-switch>-->
<!--              </p>-->
<!--              <Table :columns="view.house.columns" :data="view.house.data" style="overflow: visible;"-->
<!--                     v-show="view.house.flag">-->
<!--                <template slot-scope="{ row }" slot="thumbnail_url">-->
<!--                  <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>-->
<!--                </template>-->
<!--              </Table>-->
<!--            </Card>-->
<!--          </Row>-->
        </Form>
      </div>
      <div slot="footer">
        <Button @click="viewHandleReset()">关闭</Button>
        <Button type="primary" v-show="view.item.status==0" @click="showEditOrder(view.item)">修改计划</Button>
        <Button type="success" v-show="view.item.status==0" :loading="view.modal_loading"
                @click="passOrder(view.item)">审核通过
        </Button>
        <Button type="warning" v-show="view.item.status==3" :loading="view.modal_loading"
                @click="executeInventoryOrder(view.item)">执行计划
        </Button>

        <Button type="success" v-show="view.item.status==2 || view.item.status==1" :loading="view.modal_loading"
                @click="finishInventoryDetail(view.item.id)">盘点完成
        </Button>
      </div>
    </Modal>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>

  </div>
</template>

<script>

import NP, {minus} from 'number-precision';
import SelectGoods from '@/components/common/SelectGoods.vue';

export default {
  name: "Inventory",
  data() {
    return {
      //通用信息
      common: {
        house_all: [],
      },

      //列表
      list: {
        page_size_key: 'inventory_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        columns_info: [
          {
            title: '计划单号',
            key: 'main_no',
            slot: 'main_no',
            align: 'center'
          },
          {
            title: '计划状态',
            key: 'status',
            slot: 'status',
            align: 'center'
          },
          {
            title: '计划名称',
            key: 'name',
            align: 'center'
          },
          {
            title: '盘点仓库',
            key: 'house_name',
            align: 'center'
          },
          {
            title: '创建时间',
            slot: 'created_at',
            key: 'created_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 160,
          }
        ],
        data: [],
        total: 0,
        size: 0,
        page: 1,
        search_item: {
          main_no: '',
          status: '',
        },
        all_status: [{
          "id": 0,
          "name": '待审核'
        },
          {
            "id": 1,
            "name": '待盘点'
          },
          {
            "id": 2,
            "name": '盘点中'
          },
          {
            "id": 3,
            "name": '待执行'
          },
          {
            "id": 4,
            "name": '已执行'
          },
        ],
      },

      //创建
      createOrder: {
        model_title: '创建盘点',
        modal: false,
        modal_loading: false,
        item: {
          main_no: '',
          name: '',
          remark: '',
          area_id: '',
          house_id: '',
          goods_setted: [],
        },
        ruleInline: {
          main_no: [{
            required: true,
            message: '请填写盘点编号'
          }],
          name: [{
            required: true,
            message: '请填写计划名称'
          },],
          house_id: [{
            required: true,
            message: '请选择仓库'
          }],
        },
      },

      //查看
      view: {
        model_title: '查看盘点计划',
        modal: false,
        modal_loading: false,
        item: {
          status: '',
          remark: '',
          main_no: '',
          name: '',
          house_name: '',
          id: '',
          house_id:0,
          created_at: '',
        },
        goods: {
          columns: [
            {
              title: '商品编号',
              key: 'part_no',
              align: 'center'
            },
            {
              title: '商品名称',
              key: 'part_name',
              align: 'center'
            },
            {
              title: '缩略图',
              key: 'thumbnail_url',
              slot: 'thumbnail_url',
              align: 'center'
            },
            {
              title: '原有库存',
              key: 'sys_total',
              align: 'center'
            },
            {
              title: '盘点库存',
              key: 'real_total',
              slot: 'real_total',
              align: 'center'
            },
            {
              title: '盘点差异',
              key: 'num_diff',
              align: 'center'
            },
            {
              title: '操作',
              key: 'action',
              slot: 'action',
              width: 160,
              align: 'center'
            },

          ],
          data: [],
          tmp_data: [],
        },
        house: {
          columns: [
            {
              title: '商品编号',
              key: 'part_no',
              align: 'center'
            },
            {
              title: '商品名称',
              key: 'part_name',
              align: 'center'
            },
            {
              title: '缩略图',
              key: 'thumbnail_url',
              slot: 'thumbnail_url',
              align: 'center'
            },
            {
              title: '现有库存',
              key: 'sys_total',
              align: 'center'
            },
          ],
          data: [],
          flag: false,
        },
      },

      //添加商品
      add_goods: {
        modal: false,
        modal_loading: false,
        columns: [{
          type: 'selection',
          align: 'center',
        },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url'
          },
          {
            title: '配件号',
            key: 'part_no',
            slot: 'part_no'
          },
          {
            title: '配件名称',
            key: 'part_name'
          },
          {
            title: '类型',
            key: 'type',
            slot: 'type'
          },
          {
            title: '总库存',
            key: 'stock_all'
          },
        ],
        list: [],
        list_total: 0,
        page_size: 0,
        formItem: {
          part_no: '',
          part_name: '',
        },
        searchFlag: false, //搜索标志
        selected: [],
      },

      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 0
      },
    };
  },
  methods: {
    addGoods(goods_type) {
      this.$refs.select_goods.select_goods.goods_type = goods_type;
      this.$refs.select_goods.showGoodsList();
    },
    //获取选中的商品
    getSelectedGoods(val) {
      //设置选中的商品
      let selected_goods = val;
      let setted_goods = this.view.goods.data;

      //过滤已存在数据
      for (let i = 0; i < selected_goods.length; i++) {
        for (let j=0; j< setted_goods.length; j++) {
          if (selected_goods[i].goods_id == setted_goods[j].goods_id) {
            selected_goods.splice(i, 1);
          }
        }
      }

      if (selected_goods.length >0) {
        //获取商品库存
        let param = {
          house_id: this.view.item.house_id,
          goods_set: selected_goods,
        }

        this.$axios.post('/api/goods/getHouseGoodsStock', param).then(res => {
          if (res.data.err_no == 0) {
            let stockInfo = res.data.results;

            for (let i = 0; i < selected_goods.length;  i++) {
              for (let j = 0, len = stockInfo.length; j < len; j++) {
                if (stockInfo[j].goods_id == selected_goods[i].id) {
                  selected_goods[i].sys_total = parseFloat(stockInfo[j].num);
                }
              }

              //列表必要参数赋值及初始化
              selected_goods[i].real_total = '';
              selected_goods[i].num_diff = '';
              selected_goods[i].goods_id = selected_goods[i].id;
              selected_goods[i].add_flag = 1;
              selected_goods[i].modal_loading = false;
              selected_goods[i].id = 0;
              setted_goods.push(selected_goods[i]);
            }
          }
        });
      }
    },

    //执行计划
    executeInventoryOrder($item) {
      this.view.modal_loading = true;
      this.$Modal.confirm({
        title: "盘点计划执行",
        content: '确定执行盘点计划？执行后将改变对应库区的商品库存信息',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            inventory_id: $item.id,
            main_no: $item.main_no,
          };
          this.$axios.post('/api/goods/executeInventoryOrder', param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getMainList();
              this.view.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
            }
            this.view.modal_loading = false;
          });
        },
        onCancel: () => {
          this.view.modal_loading = false;
        }
      });
    },

    //盘点完成
    finishInventoryDetail($inventory_id) {
      this.view.modal_loading = true;

      //判断待盘点的商品是否还有
      // if (this.view.house.data.length > 0) {
      //     this.$Message.error("该库区还有商品待盘点，请核查");
      //     return;
      // }

      //盘点是否有盘点商品
      if (this.view.goods.data.length <= 0) {
        this.$Message.error("未添加盘点商品，请核查");
        this.view.modal_loading = false;
        return;
      }

      for (let i = 0; i < this.view.goods.data.length; i++) {
        if (this.view.goods.data[i].add_flag) {
          this.$Message.error("盘点明细中存在<span style='color:#ed4014;'>未保存</span>的数据，请核查");
          this.view.modal_loading = false;
          return;
        }
      }

      this.$Modal.confirm({
        title: "盘点完成",
        content: '确定盘点完成？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            inventory_id: $inventory_id,
          };
          this.$axios.post('/api/goods/finishInventoryDetail', param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.view.modal_loading = false;
              this.getMainList();
              this.view.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
        },
        onCancel: () => {
          this.view.modal_loading = false;
        }
      });
    },

    //修改盘点细节信息
    editInventoryDetail(row) {
      row.modal_loading = false;
      row.add_flag = 1;
    },

    //简单删除盘点细节信息
    simpleDeleteInventoryDetail(index, row) {
      this.view.goods.data.splice(index, 1);
    },

    //删除盘点细节信息
    deleteInventoryDetail(index, row) {
      this.$Modal.confirm({
        title: "删除盘点计划单明细",
        content: '确定删除删除盘点计划单明细？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.view.goods.data.splice(index, 1);

          if (row.id) {
            let param = {
              id: row.id,
              inventory_id: this.view.item.id,
            };
            this.$axios.post('/api/goods/deleteInventoryDetail', param).then((response) => {
              if (response.data.err_no == 0) {
                this.$Message.success(response.data.results);
                this.getValidGoods(this.view.item.area_id, this.view.item.id);
              } else {
                this.$Message.error(response.data.err_msg);
              }
            });
          }
        },
        onCancel: () => {
        }
      });
    },

    //保存盘点细节信息
    saveInventoryDetail(index, row, pd_id) {
      row.modal_loading = true;

      if (row.real_total == '') {
        this.$Message.error('请填写商品实际盘点数量');
        row.modal_loading = false;
        return;
      }

      //判断现有库存是否已填写
      let param = {
        inventory_id: pd_id,
        goods_id: row.goods_id,
        house_id: this.view.item.house_id,
        real_total: row.real_total,
        sys_total: row.sys_total
      };

      this.$axios.post('/api/goods/saveInventoryDetail', param).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          row.add_flag = 0;
          this.view.goods.data[index] = row;

          this.getValidGoods(this.view.item.area_id, this.view.item.id);
        } else {
          this.$Message.error(response.data.err_msg);
          row.modal_loading = false;
        }
      });
    },



    //库存差异
    totalDiff(row) {
      row.num_diff = NP.minus(row.real_total, row.sys_total);
    },

    //开始盘点
    startInventory(row) {
      //改变系统状态,将状态改为盘点中
      let param = {id: row.id,};
      this.$axios.post('/api/goods/startInventory', param).then((response) => {
          if (response.data.err_no == 0) {
          }
        });

      this.viewOrder(row);
    },

    //获取状态的颜色
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
        case 4:
          rs = 'color:#19be6b';
          break;
        case 0:
          rs = 'color:#ed4014';
          break;
        case 2:
          rs = 'color:#2775ff';
          break;
        case 3:
          rs = 'color:#ff9900';
          break;
        default:
          rs = '';
          break;
      }
      return rs;
    },
    getStatusText(index) {
      for (let i = 0; i < this.list.all_status.length; i++) {
        if (index == this.list.all_status[i].id) {
          return this.list.all_status[i].name;
        }
      }
    },

    //删除操作提示
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除盘点计划信息",
        content: '确定删除盘点计划信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },

    //删除计划
    deleteListInfo($id) {
      let param = {id: $id,};
      this.$axios.post('/api/goods/deleteInventoryOrder', param).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getMainList();
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },

    //获取列表
    getMainList() {
      let param = {
        page: this.list.page,
        main_no: this.list.search_item.main_no,
        status: this.list.search_item.status,
        size:this.list.size,
      };
      this.$axios.post('/api/goods/getInventoryList', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.list.data = response.data.results.list;
              this.list.total = response.data.results.total;
              this.list.size = response.data.results.size;
            }
          });
    },

    //盘点计划创建
    createOrderInfo(name) {
      this.createOrder.modal = true;
      this.createOrder.modal_loading = false;

      this.$refs[name].resetFields();
      this.$axios.get('/api/goods/getInventoryMainNo').then((response) => {
            if (response.data.err_no == 0) {
              this.createOrder.item.main_no = response.data.results;
            }
          });
      this.getWarehouseAll();
    },

    //盘点计划创建取消按钮操作
    addHandleReset() {
      //弹出框重置
      this.createOrder.modal = false;
      this.createOrder.modal_loading = false;
    },
    viewHandleReset() {
      //弹出框重置
      this.view.modal = false;
      this.view.modal_loading = false;
    },

    //创建盘点计划
    createInventoryOrder(name) {
      this.createOrder.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          let param = this.createOrder.item;
          this.$axios.post('/api/goods/createInventoryOrder', param).then((response) => {
                if (response.data.err_no === 0) {
                  this.createOrder.modal = false;
                  this.getMainList();
                } else {
                  this.$Message.error(response.data.err_msg);
                  this.createOrder.modal_loading = false;
                }
              }).catch((error) => {
                this.$Message.error("操作失败！");
                this.createOrder.modal_loading = false;
              });
        } else {
          this.createOrder.modal_loading = false;
        }

      });

    },

    //列表分页
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },

    //获取所有可用仓库列表
    getWarehouseAll: function () {
      this.$axios.get('/api/setting/getWarehouseAll').then((response) => {
        if (response.data.err_no === 0) {
          this.common.house_all = response.data.results;
        }
      });
    },



    //搜索
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    //取消搜索
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getMainList();
    },

    //修改浮层
    listEdit(row) {
      this.getWarehouseAll();

      this.createOrder.item.id = row.id;
      this.createOrder.item.main_no = row.main_no;
      this.createOrder.item.name = row.name;
      this.createOrder.item.house_id = row.house_id;
      this.createOrder.item.remark = row.remark;
      this.createOrder.model_title = '修改盘点计划';
      this.createOrder.modal_loading = false;
      this.createOrder.modal = true;
    },

    //查看计划
    viewOrder(row) {
      if (row.status == 0) {
        this.view.model_title = '盘点计划信息';
      }

      this.view.goods.data = [];
      this.view.goods.tmp_data = [];

      this.view.item = row;
      this.view.modal = true;
      this.view.modal_loading = false;
      if (row.status != 0) {
        this.getValidGoods(row.house_id, row.id);
      }
    },

    //获取过滤后的盘点数据
    getValidGoods($house_id, $inventory_id) {
      // let getAreaGoods = new Promise((resolve, reject) => {
      //   this.$axios.get('/api/goods/getAreaGoods?area_id=' + $area_id).then((res) => {
      //     if (res.data.err_no == 0) {
      //       for (let i=0; i<res.data.results.length; i++) {
      //         res.data.results[i].sys_total = parseFloat(res.data.results[i].sys_total);
      //       }
      //       this.view.house.data = res.data.results;
      //       resolve(res.data.results);
      //     } else {
      //       this.$Message.error(res.data.err_msg);
      //       resolve(res.data.err_msg);
      //     }
      //   });
      //
      //   let param = {
      //     house_id: row.house_id,
      //     goods_set: selected_goods,
      //   }
      //   this.$axios.post('/api/goods/getHouseGoodsStock', param).then(res => {
      //     if (res.data.err_no == 0) {
      //       let stockInfo = res.data.results;
      //
      //       for (let i = 0, len = selected_goods.length; i < len; i++) {
      //         for (let j = 0, len = stockInfo.length; j < len; j++) {
      //           if (stockInfo[j].goods_id == selected_goods[i].id) {
      //             selected_goods[i].sys_total = parseFloat(stockInfo[j].num);
      //           }
      //         }
      //       }
      //     }
      //   });
      // });

      let getInventoryDetail = new Promise((resolve, reject) => {
        this.$axios.get('/api/goods/getInventoryDetail?inventory_id=' + $inventory_id).then((res) => {
              if (res.data.err_no == 0) {
                for (let i = 0; i < res.data.results.length; i++) {
                  res.data.results[i].add_flag = 0;
                  res.data.results[i].real_total = parseFloat(res.data.results[i].real_total);
                  res.data.results[i].sys_total = parseFloat(res.data.results[i].sys_total);
                  res.data.results[i].num_diff = parseFloat(res.data.results[i].num_diff);

                  if (this.view.goods.data.length) {
                    for (let j = 0; j < this.view.goods.data.length; j++) {
                      if (this.view.goods.data[j].goods_id == res.data.results[i].goods_id &&
                          this.view.goods.data[j].house_id == res.data.results[i].house_id) {
                        this.view.goods.data[j] = res.data.results[i];
                        break;
                      }
                    }
                  }
                }
                if (!this.view.goods.data.length) {
                  this.view.goods.data = res.data.results;
                }
                this.view.goods.tmp_data = res.data.results;
                resolve(res.data.results);
              } else {
                this.$Message.error(res.data.err_msg);
                resolve(res.data.err_msg);
              }
            });
      });

      //等所有异步都加载完成后再去除数据
      // Promise.all([getInventoryDetail]).then((values) => {
      //   //排除已经盘点数据
      //   // this.passHouseData();
      // });
    },

    //排除已经盘点的数据
    // passHouseData() {
    //   for (let j = 0; j < this.view.goods.tmp_data.length; j++) {
    //     for (let i = 0; i < this.view.house.data.length; i++) {
    //       if (this.view.goods.tmp_data[j].goods_id == this.view.house.data[i].goods_id &&
    //           this.view.goods.tmp_data[j].position_id == this.view.house.data[i].position_id) {
    //         this.view.house.data.splice(i, 1);
    //         break;
    //       }
    //     }
    //   }
    // },

    //审核通过
    passOrder(item) {
      this.view.modal_loading = true;

      let param = {
        id: item.id,
        area_id: item.area_id,
      };

      this.$axios.post('/api/goods/passInventoryOrder', param).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getMainList();
          this.view.modal = false;
        } else {
          this.$Message.error(response.data.err_msg);
          this.view.modal_loading = false;
        }
      });
    },

    //显示修改信息
    showEditOrder(item) {
      this.listEdit(item);
      this.viewHandleReset();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  mounted() {
    this.setSelectedPageSize();
    this.getMainList();
  },
  created() {
    this.aCheck.plateName = 'product_manage';
  },
  components: {
    SelectGoods,
  }
};
</script>

<style scoped>
#inventory {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

.button-in-list-normal {
  font-size: 12px;
}

.mt4 {
  margin-top: 4px;
}

.pointer {
  cursor: pointer;
}

.f12 {
  font-size: 12px !important;
}

#panel-header {
  position: relative;
  border: none;
}

.modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#inventory .ivu-btn-small {
  font-size: 12px !important;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}
</style>
<style>
#inventory .ivu-card {
  background-color: #fafafa;
}

#inventory .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}


#inventory .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td,
.ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

.inventory .ivu-table-overflowX,
.inventory .ivu-table-tip {
  overflow-x: hidden;
}

.inventory .ivu-table-wrapper {
  overflow: visible;
}

</style>
