<template id="menu">
  <Menu theme="light" :active-name="active_name" @on-select="onselect" width="auto">
    <MenuGroup title="商品管理" v-if="aCheck.moduleCheck('product_module')">
      <MenuItem name="1" v-if="aCheck.pageCheck('product_list')">
        <Icon type="ios-albums"/>
        商品列表
      </MenuItem>
      <MenuItem name="2" v-if="aCheck.pageCheck('product_list')">
        <Icon type="ios-color-palette" />
        商品类别
      </MenuItem>
    </MenuGroup>
    <MenuGroup title="商品单据" v-if="aCheck.moduleCheck('stock_module')">
      <MenuItem name="3" v-if="aCheck.pageCheck('goods_explode_change')">
        <Icon type="ios-filing"/>
        商品拆装单
      </MenuItem>
    </MenuGroup>
    <MenuGroup title="库存管理" v-if="aCheck.moduleCheck('manage_module')">
      <!-- <MenuItem name="11" v-if="aCheck.pageCheck('warehouse_info')">
        <Icon type="ios-apps" />
        仓库概况
      </MenuItem> -->
      <MenuItem name="8" v-if="aCheck.pageCheck('goods_inventory')">
        <Icon type="logo-buffer"/>
        商品盘点
      </MenuItem>
      <!-- <MenuItem name="9" v-if="aCheck.pageCheck('stock_transfer')">
        <Icon type="ios-aperture" />
        库存调拨
      </MenuItem> -->
      <MenuItem name="10" v-if="aCheck.pageCheck('stock_log')">
        <Icon type="ios-book" />
        库存变动
      </MenuItem>
    </MenuGroup>
    <!-- <MenuGroup title="领料管理" v-if="aCheck.moduleCheck('pick_module')">
      <MenuItem name="6" v-if="aCheck.pageCheck('pick_record')">
        <Icon type="md-albums"/>
        领料记录
      </MenuItem>
      <MenuItem name="7" v-if="aCheck.pageCheck('pick_goods')">
        <Icon type="ios-archive" />
        领料货品
      </MenuItem>
    </MenuGroup> -->
  </Menu>
</template>

<script>
import * as loginUtil from '@/utils/login'

export default {
  name: "GoodsManageMenu",
  data() {
    return {
      active_name: this.active,
      menu_name: this.menuName,
    };
  },
  methods: {
    onselect(name) {
      this.$emit('func', name);
      localStorage.setItem(this.menu_name, name);
      loginUtil.setExpireTime();
    },
    setNavInfo(name) {
      this.active_name = name.toString()
    }
  },
  created() {
    this.aCheck.plateName = 'product_manage';
  },
  props: ["active", "menuName"],
};
</script>

<style scoped>
#menu {
  width: 200px;
  position: absolute;
  left: 0;
}
</style>
